<template>
  <div v-if="!isLoading">
    <v-card-text>
      <v-row v-if="!$store.state.app.onlyShow">
        <v-col
          cols="12"
          md="12"
          class="text-center"
        >
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                :disabled="markup.products.length === 0"
                class="mb-4 me-3"
                @click="clearItem()"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                :disabled="markup.products.length === 0"
                color="primary"
                :loading="loading"
                @click="save()"
              >
                <span>{{ $t('btn.update') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="4"
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <!--<v-col
          cols="12"
          md="12"
          class="text-center"
        >
          <v-row>
            <v-col
              cols="12"
              md="2"
              offset-md="2"
              class="text-right mt-1"
            >
              <h3>{{ $t('lbl.modelPvp') }}</h3>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-radio-group
                v-model="type_markups"
                row
                hide-details
                class="mt-0"
              >
                <v-radio
                  :label="$t('menu.markups')"
                  value="markups"
                  @click="changeTypeMarkups"
                ></v-radio>
                <v-radio
                  :label="$t('menu.comitions')"
                  value="comitions"
                  @click="changeTypeMarkups"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>-->

        <v-col
          cols="12"
          md="4"
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-select
                v-model="markup.products"
                :items="products"
                :label="$t('lbl.product')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="slug"
                multiple
                @change="setProduct"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.product') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="showProveedor"
              cols="12"
              md="12"
            >
              <v-select
                v-model="markup.proveedor_id"
                :items="proveedores"
                :label="$t('lbl.proveedor')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
                @change="setProveedor"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.proveedor') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="showContrate"
              cols="12"
              md="12"
            >
              <v-select
                v-model="markup.contrate_id"
                :items="contrates"
                :label="$t('lbl.contrato')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
                @change="setContrate"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.contrato') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <!--CARS-->
            <v-col
              v-if="categoryContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="markup.category_id"
                :items="categoryContrate"
                :label="$t('lbl.cat')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.cat') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="marcasContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="markup.marca_modelo_id"
                :items="marcasContrate"
                :label="$t('lbl.brandModel')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.brandModel') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <!--FLIGHTS-->
            <v-col
              v-if="flightContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="markup.flight_id"
                :items="flightContrate"
                :label="$t('lbl.vuelo')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.vuelo') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <!--HOTELS-->
            <v-col
              v-if="hotelesContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="markup.hotel_id"
                :items="hotelesContrate"
                :label="$t('lbl.hotel')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
                @change="setHotel"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.hotel') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col
              v-if="roomsContrate.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="markup.room_id"
                :items="roomsContrate"
                :label="$t('lbl.room')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.room') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col
              v-if="temporadas.length > 0"
              cols="12"
              md="12"
            >
              <v-select
                v-model="markup.temporada_id"
                :items="temporadas"
                :label="$t('lbl.temporadas')"
                outlined
                dense
                hide-details
                item-text="name"
                item-value="id"
                multiple
                @change="setTemporada"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('lbl.temporadas') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col
              v-if="showDateFrom"
              cols="12"
              md="6"
            >
              <v-menu
                v-model="menuFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedFromDateFormatted"
                    :label="$t('lbl.from')"
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="markup.from"
                  no-title
                  :min="new Date(minDate).toISOString().substr(0, 10)"
                  locale="es"
                  :show-current="false"
                  @input="
                    menuFrom = false
                    menuTo = true
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              v-if="showDateFrom && markup.from"
              cols="12"
              md="6"
            >
              <v-menu
                v-model="menuTo"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedToDateFormatted"
                    :label="$t('lbl.to')"
                    :disabled="markup.from === null"
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <!--
                  :max="
                    markup.to
                      ? $moment(markup.to)
                        .toDate()
                        .toISOString()
                        .substr(0, 10)
                      : ''
                  "
                -->
                <v-date-picker
                  v-model="markup.to"
                  no-title
                  locale="es"
                  :show-current="false"
                  :min="
                    markup.from
                      ? $moment(markup.from)
                        .add(1, 'day')
                        .toDate()
                        .toISOString()
                        .substr(0, 10)
                      : ''
                  "
                  @input="menuTo = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="6"
          offset-md="1"
        >
          <v-row>
            <v-col
              cols="12"
              md="12"
              class="text-center"
            >
              <h3 class="text-uppercase">
                {{ markup.is_comition ? $t('lbl.comitionsOwner') : $t('lbl.markupsOwner') }}
              </h3>
            </v-col>
            <v-col
              cols="12"
              md="12"
              class="text-center"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <h3 class="mt-2">
                    {{ $t('lbl.saleFisic') }}
                  </h3>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="markup.data_sale_fisic.value"
                    :label="$t('lbl.fisic')"
                    type="number"
                    hide-details
                    dense
                    outlined
                    :prefix="markup.data_sale_fisic.is_value ? '$' : ''"
                    :suffix="!markup.data_sale_fisic.is_value ? '%' : ''"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-switch
                    v-model="markup.data_sale_fisic.is_value"
                    :label="$t('lbl.valor')"
                    hide-details
                    class="mt-2"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <h3 class="mt-2">
                    {{ $t('lbl.saleOnline') }}
                  </h3>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    v-model="markup.data_sale_online.value"
                    label="Online"
                    type="number"
                    hide-details
                    dense
                    outlined
                    :prefix="markup.data_sale_online.is_value ? '$' : ''"
                    :suffix="!markup.data_sale_online.is_value ? '%' : ''"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-switch
                    v-model="markup.data_sale_online.is_value"
                    :label="$t('lbl.valor')"
                    hide-details
                    class="mt-2"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              v-if="user.nivel !== 2"
              cols="12"
              md="12"
              class="text-center"
            >
              <h3 class="text-uppercase">
                {{ markup.is_comition ? $t('lbl.comitionsAfiliate') : $t('lbl.markupsAfiliate') }}
              </h3>
            </v-col>
            <v-col
              cols="12"
              md="12"
              class="text-center"
            >
              <fragment v-if="user.nivel !== 2">
                <v-row
                  v-for="(afiliate, indA) in markup.data_sale_afiliate"
                  :key="indA"
                >
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <h3 class="mt-2">
                      <v-tooltip
                        top
                        color="primary"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <span
                            style="cursor: pointer;"
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{
                              typeAfiliados.filter(e => e.id === afiliate.type_id).length > 0
                                ? typeAfiliados.filter(e => e.id === afiliate.type_id)[0].name
                                : ''
                            }}
                          </span>
                        </template>
                        <span>
                          {{
                            typeAfiliados.filter(e => e.id === afiliate.type_id).length > 0
                              ? typeAfiliados.filter(e => e.id === afiliate.type_id)[0].description
                              : ''
                          }}
                        </span>
                      </v-tooltip>
                    </h3>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="afiliate.value"
                      :label="
                        typeAfiliados.filter(e => e.id === afiliate.type_id).length > 0
                          ? typeAfiliados.filter(e => e.id === afiliate.type_id)[0].name
                          : ''
                      "
                      type="number"
                      hide-details
                      dense
                      outlined
                      :prefix="afiliate.is_value ? '$' : ''"
                      :suffix="!afiliate.is_value ? '%' : ''"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-switch
                      v-model="afiliate.is_value"
                      :label="$t('lbl.valor')"
                      hide-details
                      class="mt-2"
                    ></v-switch>
                  </v-col>
                </v-row>
              </fragment>

              <!--<v-row>
                <v-col
                  cols="12"
                  md="4"
                ></v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-btn
                    v-if="permisos.includes('markup:edit') || permisos.includes('*')"
                    color="primary"
                    large
                    block
                    :loading="loading"
                    @click="save()"
                  >
                    <span>{{ $t('btn.save') }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                ></v-col>
              </v-row>-->
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--<v-row>
        <v-col
          v-if="permisos.includes('flights:create') || permisos.includes('*')"
          cols="12"
          md="2"
        >
          <v-btn
            v-if="currentTab === 0"
            color="primary"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="$store.state.app.itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>-->
    </v-card-text>

    <v-tabs v-model="currentTab">
      <v-tab v-if="productsPermit.includes('vuelos')">
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiAirplane }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.flights') }}</span>
        </v-tooltip>
      </v-tab>
      <v-tab v-if="productsPermit.includes('cars')">
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiCar }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.cars') }}</span>
        </v-tooltip>
      </v-tab>
      <v-tab v-if="productsPermit.includes('hotels')">
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>{{ icons.mdiOfficeBuilding }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('menu.hotels') }}</span>
        </v-tooltip>
      </v-tab>
    </v-tabs>

    <v-divider></v-divider>

    <v-tabs-items v-model="currentTab">
      <v-tab-item v-if="productsPermit.includes('vuelos')">
        <FlightsTab
          v-if="showTabs"
          :is-comition="markup.is_comition"
          :proveedores="proveedoresFlight"
          :contrates="contratesFlight"
          :flights="flightsList"
          :type-afiliados="user.nivel !== 2 ? typeAfiliados : []"
          :user="user"
          @asign:markups="asignMarkups($event)"
          @load="load()"
        />
      </v-tab-item>
      <v-tab-item v-if="productsPermit.includes('cars')">
        <CarsTab
          v-if="showTabs"
          :is-comition="markup.is_comition"
          :proveedores-car="proveedoresCar"
          :contrates-car="contratesCar"
          :categories-car="categoriesCar"
          :marcas-car="marcasCar"
          :type-afiliados="user.nivel !== 2 ? typeAfiliados : []"
          :user="user"
          @asign:markups="asignMarkups($event)"
          @load="load()"
        />
      </v-tab-item>
      <v-tab-item v-if="productsPermit.includes('hotels')">
        <HotelsTab
          v-if="showTabs"
          :is-comition="markup.is_comition"
          :proveedores-hotel="proveedoresHotel"
          :contrates-hotel="contratesHotel"
          :hotels="hotelesList"
          :rooms="roomsList"
          :type-afiliados="user.nivel !== 2 ? typeAfiliados : []"
          :user="user"
          @asign:markups="asignMarkups($event)"
          @load="load()"
        />
      </v-tab-item>
    </v-tabs-items>

    <!-- MOSTRAR CHANGES -->
    <v-dialog
      v-model="changesNotUpdate"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ $t('btn.update') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('lbl.notChangesUpdate') }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="changesNotUpdate = !changesNotUpdate"
          >
            {{ $t('btn.cancele') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="continueClear()"
          >
            {{ $t('btn.apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiAirplane,
  mdiCar,
  mdiOfficeBuilding,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
// import FlightTab from '../tabs/Flights.vue'

import FlightsTab from '../tabs/Flights.vue'
import CarsTab from '../tabs/Cars.vue'
import HotelsTab from '../tabs/Hotels.vue'

export default {
  components: {
    FlightsTab,
    CarsTab,
    HotelsTab,
  },
  data() {
    return {
      isLoading: true,
      loading: false,
      currentTab: 0,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiAirplane,
        mdiCar,
        mdiOfficeBuilding,
      },
      itemsPerPage: 10,
      permisos: localStorage.getItem('permisos_auth'),
      markup: {},
      type_markups: 'markups',
      products: [],
      proveedoresHotel: [],
      proveedoresCar: [],
      proveedoresFlight: [],
      proveedores: [],
      minDate: Date.now(),
      maxDate: Date.now(),
      menuFrom: false,
      menuTo: false,
      contrates: [],
      contratesHotel: [],
      hotelesList: [],
      hotelesContrate: [],
      roomsList: [],
      roomsContrate: [],
      flightsList: [],
      contratesCar: [],
      categoriesCar: [],
      marcasCar: [],
      contratesFlight: [],
      categoryContrate: [],
      marcasContrate: [],
      flightContrate: [],
      temporadas: [],
      typeAfiliados: [],
      showTabs: true,
      items: [],
      user: {},
      productsPermit: [],
      changesNotUpdate: false,
    }
  },
  computed: {
    computedFromDateFormatted() {
      return this.markup.from ? this.$moment(this.markup.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.markup.to ? this.$moment(this.markup.to).format('D-MMM-YY') : ''
    },

    showProveedor() {
      if (!this.markup.products.includes('all') && this.markup.products.length === 1) {
        return true
      }

      return false
    },
    showDateFrom() {
      if (this.markup.products.length > 0) {
        return true
      }

      return false
    },
    showContrate() {
      if (!this.markup.proveedor_id.includes(-1) && this.markup.proveedor_id.length === 1) {
        return true
      }

      return false
    },
  },
  mounted() {
    this.profile()
  },
  created() {
    this.resetMarkups()
    this.resetTemporadas()
    this.getTypeAfiliado()

    this.load()

    this.getProveedoresHotel()
    this.getProveedoresCar()
    this.getProveedoresFlights()

    this.getContratesHotel()
    this.getContratesCar()
    this.getContratesFlight()
    this.getCategoriesCar()
    this.getMarcasCar()

    this.getHoteles()
    this.getRooms()
    this.getFlights()
  },
  methods: {
    ...mapMutations(['updateMarkupsList', 'deleteMarkupsList']),
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getProductsPermit()

          // this.isLoading = false
        })
    },
    changeTypeMarkups() {
      if (this.type_markups === 'markups') {
        this.markup.is_comition = false
      } else {
        this.markup.is_comition = true
      }
    },
    load() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('markups/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // console.log(res)
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            this.items = res.data.data
            this.updateMarkupsList(this.items)
          }
        })
    },
    getProducts() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.products.push({
            slug: 'all',
            name: 'Todos',
          })
          const rrr = res.data.data

          this.productsPermit.forEach(elementPermit => {
            rrr.forEach(element => {
              if (elementPermit === element.entity) {
                if (
                  element.entity !== 'transfers'
                  && element.entity !== 'excursions'
                  && element.entity !== 'packages'
                  && element.entity !== 'cruise_ships'
                ) {
                  this.products.push({
                    slug: element.entity,
                    name: element.name,
                  })
                }
              } else if (elementPermit === 'vuelos' && element.entity === 'flights') {
                if (
                  element.entity !== 'transfers'
                  && element.entity !== 'excursions'
                  && element.entity !== 'packages'
                  && element.entity !== 'cruise_ships'
                ) {
                  this.products.push({
                    slug: element.entity,
                    name: element.name,
                  })
                }
              }
            })
          })
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getProductsPermit() {
      this.axios
        .get('type_product?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          if (this.user.company_id) {
            if (this.user.company.products.length > 0) {
              result.forEach(element => {
                if (this.user.company.products.includes(element.slug)) {
                  this.productsPermit.push(element.slug)
                }
              })
            }
          } else {
            result.forEach(element => {
              this.productsPermit.push(element.slug)
            })
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.getProducts()
        })
    },
    getTypeAfiliado() {
      this.axios
        .get(`tipo_afiliado?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const result = res.data.data
          result.forEach(element => {
            if (element.slug !== 'propios') {
              this.typeAfiliados.push(element)
            }
          })
        })
        .finally(() => this.verifyAfiliate())
    },
    verifyAfiliate() {
      this.typeAfiliados.forEach(element => {
        this.markup.data_sale_afiliate.push({
          type_id: element.id,
          value: null,
          is_value: false,
        })
      })

      this.isLoading = false
    },
    getProveedoresHotel() {
      this.axios
        .post('proveedors/byproduct', { slug: 'hotels', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedoresHotel = res.data.data
        })
    },
    getProveedoresCar() {
      this.axios
        .post('proveedors/byproduct', { slug: 'cars', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedoresCar = res.data.data
        })
    },
    getProveedoresFlights() {
      this.axios
        .post('proveedors/byproduct', { slug: 'vuelos', only_contrate: true }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedoresFlight = res.data.data
        })
    },
    getContratesHotel() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('contrate_hotels/index', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.contratesHotel = res.data.data
          }
        })
    },
    getContratesCar() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('contrate_cars/index', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.contratesCar = res.data.data
          }
        })
    },
    getContratesFlight() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('contrate_flights/index', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.contratesFlight = res.data.data
          }
        })
    },
    getCategoriesCar() {
      this.axios
        .get('nom_categories_cars?per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.categoriesCar = res.data.data
          }
        })
    },
    getMarcasCar() {
      const json = {
        per_page: 10000,
        search: { marca_id: 0 },
      }
      this.axios
        .post('cars/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.marcasCar = res.data.data
          }
        })
    },
    getHoteles() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            /* this.$toast.error(this.$t('msg.noAutorice'))
          this.$store.commit('logout') */
          } else {
            this.hotelesList = res.data.data
          }
        })
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=10000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.roomsList = res.data.data
        })
    },
    getFlights() {
      const json = {
        per_page: 10000,
      }
      this.axios
        .post('flight/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            /* this.$toast.error(this.$t('msg.noAutorice'))
          this.$store.commit('logout') */
          } else {
            this.flightsList = res.data.data
          }
        })
    },

    setProduct() {
      if (this.markup.products) {
        // VERIFICAR SI CONTIENE ALL
        if (!this.markup.products.includes('all') && this.markup.products.length === 1) {
          this.proveedores.push({
            id: -1,
            name: 'Todos',
          })

          /* if (!this.markup.id) {
            this.markup.proveedor_id = [this.proveedores[0].id]
          } */

          // BUSCAR LOS PROVEEDORES DE LOS PRODUCTOS
          if (this.markup.products[0] === 'hotels') {
            this.proveedoresHotel.forEach(element => {
              this.proveedores.push({
                id: element.id,
                name: element.name_comercial,
              })
            })
          } else if (this.markup.products[0] === 'cars') {
            this.proveedoresCar.forEach(element => {
              this.proveedores.push({
                id: element.id,
                name: element.name_comercial,
              })
            })
          } else if (this.markup.products[0] === 'flights') {
            this.proveedoresFlight.forEach(element => {
              this.proveedores.push({
                id: element.id,
                name: element.name_comercial,
              })
            })
          }

          // AUTOSELECT
          if (this.proveedores.length === 1) {
            this.proveedores.splice(0, 1)
          } else if (this.proveedores.length === 2) {
            this.proveedores.splice(0, 1)
            if (!this.markup.id) {
              this.markup.proveedor_id = [this.proveedores[0].id]
              this.setProveedor()
            }
          }
        } else {
          this.resetProducts()
        }

        /* this.markup.from = this.$moment(new Date())
          .toDate()
          .toISOString()
          .substr(0, 10) */
      } else {
        this.resetProducts()
      }
    },
    resetProducts() {
      this.proveedores = []
      this.markup.proveedor_id = []
      this.contrates = []
      this.markup.contrate_id = []
      this.categoryContrate = []
      this.markup.category_id = []
      this.marcasContrate = []
      this.markup.marca_modelo_id = []
      this.temporadas = []
      this.markup.temporada_id = []
      this.hotelesContrate = []
      this.markup.hotel_id = []
      this.roomsContrate = []
      this.markup.room_id = []
      this.flightContrate = []
      this.markup.flight_id = []
    },
    setProveedor() {
      if (this.markup.proveedor_id) {
        if (!this.markup.proveedor_id.includes(-1) && this.markup.proveedor_id.length === 1) {
          this.contrates.push({
            id: -1,
            name: 'Todos',
          })

          /* if (!this.markup.id) {
            this.markup.contrate_id = [this.contrates[0].id]
          } */

          // BUSCAR LOS CONTRATOS DE LOS PRODUCTOS
          if (this.markup.products[0] === 'hotels') {
            this.contratesHotel.forEach(element => {
              if (this.markup.proveedor_id[0] === element.proveedor_id) {
                this.contrates.push({
                  id: element.id,
                  name: element.identificador,
                })
              }
            })
          } else if (this.markup.products[0] === 'cars') {
            this.contratesCar.forEach(element => {
              if (this.markup.proveedor_id[0] === element.proveedor_id) {
                this.contrates.push({
                  id: element.id,
                  name: element.identificador,
                })
              }
            })
          } else if (this.markup.products[0] === 'flights') {
            this.contratesFlight.forEach(element => {
              if (this.markup.proveedor_id[0] === element.proveedor_id) {
                this.contrates.push({
                  id: element.id,
                  name: element.identificador,
                })
              }
            })
          }

          // AUTOSELECT
          if (this.contrates.length === 1) {
            this.contrates.splice(0, 1)
          } else if (this.contrates.length === 2) {
            this.contrates.splice(0, 1)
            if (!this.markup.id) {
              this.markup.contrate_id = [this.contrates[0].id]
              this.setContrate()
            }
          }
        } else {
          this.resetProveedor()
        }
      } else {
        this.resetProveedor()
      }
    },
    resetProveedor() {
      this.contrates = []
      this.markup.contrate_id = []
      this.categoryContrate = []
      this.markup.category_id = []
      this.marcasContrate = []
      this.markup.marca_modelo_id = []
      this.temporadas = []
      this.markup.temporada_id = []
      this.hotelesContrate = []
      this.markup.hotel_id = []
      this.roomsContrate = []
      this.markup.room_id = []
      this.flightContrate = []
      this.markup.flight_id = []
    },
    setContrate() {
      if (!this.markup.contrate_id.includes(-1) && this.markup.contrate_id.length === 1) {
        if (this.markup.products.includes('cars')) {
          const contrateSelect = this.contratesCar.filter(e => e.id === this.markup.contrate_id[0])
          if (contrateSelect.length > 0) {
            this.temporadas.push({
              id: 'all',
              name: 'Todas',
              dates: [],
            })

            /* if (!this.markup.id) {
              this.markup.temporada_id = [this.temporadas[0].id]
            } */

            if (contrateSelect[0].temporadas.length > 0) {
              contrateSelect[0].temporadas.forEach(seas => {
                this.temporadas.push({
                  id: seas.temporada_id,
                  name: seas.name,
                  dates: seas.dates,
                })
              })

              // AUTOSELECT
              if (this.temporadas.length === 1) {
                this.temporadas.splice(0, 1)
              } else if (this.temporadas.length === 2) {
                this.temporadas.splice(0, 1)
                if (!this.markup.id) {
                  this.markup.temporada_id = [this.temporadas[0].id]
                }
              }
            }
            if (contrateSelect[0].tipo_contrato === 'categoria') {
              this.categoryContrate.push({
                id: -1,
                name: 'Todas',
              })
              if (contrateSelect[0].categories) {
                contrateSelect[0].categories.forEach(cate => {
                  this.categoryContrate.push({
                    id: cate.category_id.id,
                    name: cate.category_id.name,
                  })
                })
              }

              /* if (!this.markup.id) {
                this.markup.category_id = [this.categoryContrate[0].id]
              } */
              // AUTOSELECT
              if (this.categoryContrate.length === 1) {
                this.categoryContrate.splice(0, 1)
              } else if (this.categoryContrate.length === 2) {
                this.categoryContrate.splice(0, 1)
                if (!this.markup.id) {
                  this.markup.category_id = [this.categoryContrate[0].id]
                }
              }
            } else {
              this.marcasContrate.push({
                id: -1,
                name: 'Todas',
              })
              if (contrateSelect[0].marcas) {
                contrateSelect[0].marcas.forEach(mar => {
                  mar.cars_id.forEach(car => {
                    this.marcasContrate.push({
                      id: car.id,
                      name: car.name,
                    })
                  })
                })
              }

              /* if (!this.markup.id) {
                this.markup.marca_modelo_id = [this.marcasContrate[0].id]
              } */
              // AUTOSELECT
              if (this.marcasContrate.length === 1) {
                this.marcasContrate.splice(0, 1)
              } else if (this.marcasContrate.length === 2) {
                this.marcasContrate.splice(0, 1)
                if (!this.markup.id) {
                  this.markup.marca_modelo_id = [this.marcasContrate[0].id]
                }
              }
            }
          }
        } else if (this.markup.products.includes('hotels')) {
          const contrateSelect = this.contratesHotel.filter(e => e.id === this.markup.contrate_id[0])
          if (contrateSelect.length > 0) {
            this.hotelesContrate.push({
              id: -1,
              name: 'Todos',
            })
            const hotelesPactados = contrateSelect[0].hotels_pactados

            hotelesPactados.forEach(hote => {
              const ppp = this.hotelesList.filter(e => e.id === hote)
              if (ppp.length > 0) {
                this.hotelesContrate.push({
                  id: ppp[0].id,
                  name: ppp[0].name,
                })
              }
            })

            /* if (!this.markup.id) {
              this.markup.hotel_id = [this.hotelesContrate[0].id]
            } */
            // AUTOSELECT
            if (this.hotelesContrate.length === 1) {
              this.hotelesContrate.splice(0, 1)
            } else if (this.hotelesContrate.length === 2) {
              this.hotelesContrate.splice(0, 1)
              if (!this.markup.id) {
                this.markup.hotel_id = [this.hotelesContrate[0].id]
                this.setHotel()
              }
            }
          }
        } else if (this.markup.products.includes('flights')) {
          const contrateSelect = this.contratesFlight.filter(e => e.id === this.markup.contrate_id[0])
          if (contrateSelect.length > 0) {
            this.flightContrate.push({
              id: -1,
              name: 'Todos',
            })
            const flightId = contrateSelect[0].flight
            if (flightId !== null) {
              this.flightContrate.push({
                id: flightId.id,
                name: flightId.numero_de_vuelo,
              })
            }

            /* if (!this.markup.id) {
              this.markup.flight_id = [this.flightContrate[0].id]
            } */
            // AUTOSELECT
            if (this.flightContrate.length === 1) {
              this.flightContrate.splice(0, 1)
            } else if (this.flightContrate.length === 2) {
              this.flightContrate.splice(0, 1)
              if (!this.markup.id) {
                this.markup.flight_id = [this.flightContrate[0].id]
              }
            }
          }
        }
      } else {
        this.resetContrate()
      }
    },
    resetContrate() {
      this.categoryContrate = []
      this.markup.category_id = []
      this.marcasContrate = []
      this.markup.marca_modelo_id = []
      this.temporadas = []
      this.markup.temporada_id = []
      this.hotelesContrate = []
      this.markup.hotel_id = []
      this.roomsContrate = []
      this.markup.room_id = []
      this.flightContrate = []
      this.markup.flight_id = []
    },
    setTemporada() {
      if (!this.markup.temporada_id.includes('all') && this.markup.temporada_id.length === 1) {
        const thisSeason = this.temporadas.filter(e => e.id === this.markup.temporada_id[0])
        if (thisSeason.length > 0) {
          if (thisSeason[0].dates.length > 0) {
            if (thisSeason[0].dates[0].from) {
              /* if (this.markup.id === null) {
                this.markup.from = this.$moment(thisSeason[0].dates[0].from)
                  .toDate()
                  .toISOString()
                  .substr(0, 10)
              } */
              this.minDate = this.$moment(thisSeason[0].dates[0].from)
            }
            if (thisSeason[0].dates[thisSeason[0].dates.length - 1].to) {
              /* if (this.markup.id === null) {
                this.markup.to = this.$moment(thisSeason[0].dates[thisSeason[0].dates.length - 1].to)
                  .toDate()
                  .toISOString()
                  .substr(0, 10)
              } */

              this.maxDate = this.$moment(thisSeason[0].dates[thisSeason[0].dates.length - 1].to)
            }
          }
        }
      } else if (!this.markup.id) {
        this.resetTemporadas()
      }
    },
    resetTemporadas() {
      if (this.markup.id === null) {
        this.minDate = this.$moment(Date.now())
        if (this.markup.from) {
          const y = this.$moment(this.markup.from).format('Y')
          const m = this.$moment(this.markup.from).format('M')
          if (m > 10) {
            const ny = parseInt(y, 10) + 1

            /* this.markup.to = this.$moment(`${ny}-10-31`)
              .toDate()
              .toISOString()
              .substr(0, 10) */
            this.maxDate = this.$moment(`${ny}-10-31`)
          } else {
            /* this.markup.to = this.$moment(`${y}-10-31`)
              .toDate()
              .toISOString()
              .substr(0, 10) */
            this.maxDate = this.$moment(`${y}-10-31`)
          }
        }
      }
    },

    setHotel() {
      if (!this.markup.hotel_id.includes('all') && this.markup.hotel_id.length === 1) {
        const thisHotel = this.hotelesList.filter(e => e.id === this.markup.hotel_id[0])
        if (thisHotel.length > 0) {
          const arrSeason = []
          const cont = this.contratesHotel.filter(e => e.id === this.markup.contrate_id[0])
          if (cont.length > 0) {
            const { seasons } = cont[0]
            if (seasons.length > 0) {
              seasons.forEach(seas => {
                if (seas.hotels.includes(this.markup.hotel_id[0])) {
                  if (seas.seasons.seasons.length > 0) {
                    seas.seasons.seasons.forEach(ace => {
                      arrSeason.push({
                        id: ace.temporada_id,
                        name: ace.name,
                        dates: [
                          {
                            from: ace.from,
                            to: ace.to,
                          },
                        ],
                      })
                    })
                  }
                }
              })
            }
          }

          this.temporadas.push({
            id: 'all',
            name: 'Todas',
            dates: [],
          })
          arrSeason.forEach(arrS => {
            this.temporadas.push(arrS)
          })

          /* if (!this.markup.id) {
            this.markup.temporada_id = [this.temporadas[0].id]
          } */

          /* if (contrateSelect[0].temporadas.length > 0) {
            contrateSelect[0].temporadas.forEach(seas => {
              this.temporadas.push({
                id: seas.temporada_id,
                name: seas.name,
                dates: seas.dates,
              })
            })
          } */

          const roomHotel = thisHotel[0].rooms_id
          this.roomsContrate.push({
            id: -1,
            name: 'Todas',
          })
          roomHotel.forEach(roo => {
            const ppp = this.roomsList.filter(e => e.id === roo)
            if (ppp.length > 0) {
              this.roomsContrate.push({
                id: ppp[0].id,
                name: ppp[0].name,
              })
            }
          })

          /* if (!this.markup.id) {
            this.markup.room_id = [this.roomsContrate[0].id]
          } */
          // AUTOSELECT
          if (this.roomsContrate.length === 1) {
            this.roomsContrate.splice(0, 1)
          } else if (this.roomsContrate.length === 2) {
            this.roomsContrate.splice(0, 1)
            if (!this.markup.id) {
              this.markup.room_id = [this.roomsContrate[0].id]
            }
          }
        }
      } else if (!this.markup.id) {
        this.resetHotel()
      }
    },
    resetHotel() {
      this.roomsContrate = []
      this.markup.room_id = []
      this.temporadas = []
      this.markup.temporada_id = []
    },

    asignMarkups(id) {
      this.categoryContrate = []
      this.marcasContrate = []
      this.hotelesContrate = []
      this.roomsContrate = []
      this.temporadas = []

      const mmm = this.items.filter(e => e.id === id)
      if (mmm.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.markup = mmm[0]
      }
      this.setProduct()
      this.setProveedor()
      this.setContrate()
      this.setHotel()
      this.setTemporada()
    },
    save() {
      if (this.markup.products.length > 0) {
        this.loading = true
        if (this.markup.id === null) {
          this.axios
            .post('markups', this.markup, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // console.log(response)
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                }
              } else {
                this.load()
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.resetMarkups())
        } else {
          this.axios
            .put(`markups/${this.markup.id}`, this.markup, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                this.load()
                window.scrollTo(0, 10000)
                this.$toast.success(this.$t('msg.infoSuccess'))
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.resetMarkups())
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    clearItem() {
      // DETECTAR SI HUBO CAMBIOS
      /* if (
        this.ofertEBBDates.identificador !== this.ofertEBBDatesOrigin.data.identificador
        || this.ofertEBBDates.type_offert !== this.ofertEBBDatesOrigin.data.type_offert
        || this.ofertEBBDates.fromReserva !== this.ofertEBBDatesOrigin.data.fromReserva
        || this.ofertEBBDates.toReserva !== this.ofertEBBDatesOrigin.data.toReserva
        || this.ofertEBBDates.rooming !== this.ofertEBBDatesOrigin.data.rooming
        || this.ofertEBBDates.datePublic !== this.ofertEBBDatesOrigin.data.datePublic
        || this.ofertEBBDates.dateUpdate !== this.ofertEBBDatesOrigin.data.dateUpdate
        || this.ofertEBBDates.dateUpdateOld !== this.ofertEBBDatesOrigin.data.dateUpdateOld
        || this.ofertEBBDates.dateUpdateSaved !== this.ofertEBBDatesOrigin.data.dateUpdateSaved
        || this.ofertEBBDates.dateCancele !== this.ofertEBBDatesOrigin.data.dateCancele
        || this.ofertEBBDates.stopSale !== this.ofertEBBDatesOrigin.data.stopSale
        || this.ofertEBBDates.dateStopSale !== this.ofertEBBDatesOrigin.data.dateStopSale
        || this.ofertEBBDates.modelo !== this.ofertEBBDatesOrigin.data.modelo
        || this.ofertEBBDates.calculadoPor !== this.ofertEBBDatesOrigin.data.calculadoPor
        || this.ofertEBBDates.by_suplement_reductions !== this.ofertEBBDatesOrigin.data.by_suplement_reductions
        || this.ofertEBBDates.price_by_room !== this.ofertEBBDatesOrigin.data.price_by_room
      ) {
        this.changesNotUpdate = true
      } else if (this.ofertEBBDates.pays.length !== this.ofertEBBDatesOrigin.data.pays.length) {
        this.changesNotUpdate = true
      } else if (this.ofertEBBDates.hotels.length !== this.ofertEBBDatesOrigin.data.hotels.length) {
        this.changesNotUpdate = true
      } else {
        this.continueClear()
      } */

      this.continueClear()

      // this.resetItems()
      // this.emptyOfertEBB()
    },
    continueClear() {
      this.changesNotUpdate = false
      this.resetMarkups()

      // this.emptyOfertEBB()
    },

    resetMarkups() {
      this.type_markups = 'markups'
      this.categoryContrate = []
      this.marcasContrate = []
      this.hotelesContrate = []
      this.roomsContrate = []
      this.flightContrate = []
      this.temporadas = []
      this.markup = {
        id: null,
        is_comition: false,
        products: [],
        proveedor_id: [],
        contrate_id: [],

        // CARS
        category_id: [],
        marca_modelo_id: [],

        // HOTELS
        hotel_id: [],
        room_id: [],

        // FLIGHTS
        aeroline_id: [],
        flight_id: [],

        temporada_id: [],
        from: null,

        /* from: this.$moment(new Date())
          .toDate()
          .toISOString()
          .substr(0, 10), */
        to: null,

        /* to: this.$moment(new Date())
          .toDate()
          .toISOString()
          .substr(0, 10), */
        data_sale_fisic: {
          value: null,
          is_value: false,
        },
        data_sale_online: {
          value: null,
          is_value: false,
        },
        data_sale_afiliate: [],
      }

      this.verifyAfiliate()

      this.loading = false
      this.showTabs = true
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
